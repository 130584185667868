import React from 'react';

import {Button} from '../primitives/Button';
import {NEWSLETTER_URL} from '~/consts';
import {Grid} from '~/assets/Svgs';
import COMMUNITY_IMAGE from '~/assets/community.png';

const NewsLetter = () => {
  return (
    <div className="container pb-10">
      <div className="p-8  md:p-14 rounded-2xl pb-48 sm:pb-32 md:pb-14 space-y-8 md:space-y-12 bg-honey-500 text-primary relative overflow-hidden">
        <Grid className="absolute top-0 -translate-y-1/4 opacity-10 left-0 h-[200%]" />
        <div className="space-y-4">
          <h1 className="font-heading text-[32px] leading-[110%] relative z-10">
            Never Miss <br /> a Drop
          </h1>
          <p className="paragraph-leading max-w-[300px] relative z-10">
            No more FOMO. Join our newsletter for updates on future drops,
            deals, and more.
          </p>
        </div>
        <img
          src={COMMUNITY_IMAGE}
          alt=""
          className="absolute -right-20 sm:-right-[612px] md:-right-80 xl:-right-40 2xl:-right-10 bottom-0 min-w-[120%] sm:min-w-[160%]  md:min-w-[auto] md:w-[1080px]"
        />
        <Button mode="ruby" href={NEWSLETTER_URL} className="relative z-10">
          Subscribe
        </Button>
      </div>
    </div>
  );
};

export default NewsLetter;
